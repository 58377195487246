<template>
    <svg
        height="12px"
        viewBox="0 0 3 12"
        width="3px"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
            <g
                stroke="var(--ci-primary-color, currentColor)"
                transform="translate(-89, -146)"
            >
                <g transform="translate(89, 146)">
                    <circle
                        cx="1.5"
                        cy="1.5"
                        r="1"
                        transform="translate(1.5, 1.5) scale(-1, 1) translate(-1.5, -1.5)"
                    />
                    <circle
                        cx="1.5"
                        cy="6"
                        r="1"
                        transform="translate(1.5, 6) scale(-1, 1) translate(-1.5, -6)"
                    />
                    <circle
                        cx="1.5"
                        cy="10.5"
                        r="1"
                        transform="translate(1.5, 10.5) scale(-1, 1) translate(-1.5, -10.5)"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

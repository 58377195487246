export const logoSmall = [
    '110 110',
    `
<g id="logo-small" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path d="M18.9633,78.2556 L16.8653,79.5176 L16.6333,79.1306 C14.7523,76.0026 11.8793,75.8896 9.6783,77.2136 C7.4783,78.5366 6.2113,81.0676 8.3173,84.5686 L9.9133,87.2216 L20.5193,80.8426 L18.9633,78.2556 Z M10.8793,83.1166 L10.5703,82.6016 C9.8563,81.4176 10.2003,80.3516 11.2033,79.7486 C12.1943,79.1536 13.3133,79.3226 14.0323,80.5186 L14.3413,81.0346 L10.8793,83.1166 Z" fill="#17FFC6"></path>
    <path d="M9.6085,74.5543 C12.9285,73.6623 15.1105,70.2793 14.1445,66.6843 C13.3765,63.8303 10.9645,62.0233 8.3125,61.9293 L9.1585,65.0733 C10.2395,65.3413 11.1595,66.2133 11.4825,67.4183 C11.9535,69.1703 11.1425,70.8333 9.6935,71.4553 L7.1525,62.0063 C3.3055,62.4353 0.7405,66.2493 1.7535,70.0163 C2.7205,73.6103 6.3055,75.4443 9.6085,74.5543 M4.4705,69.3173 C4.0665,67.8103 4.6335,66.3363 5.7935,65.6203 L7.4725,71.8683 C6.1165,71.7963 4.8765,70.8243 4.4705,69.3173" fill="#17FFC6"></path>
    <polygon fill="#17FFC6" points="0 53.0105 0.189 60.9995 3.056 60.9295 2.999 58.4545 12.517 58.2285 12.446 55.2105 2.927 55.4355 2.867 52.9435"></polygon>
    <polygon fill="#17FFC6" points="0.3978 50.0369 12.6348 51.6669 13.0358 48.6599 0.7978 47.0299"></polygon>
    <polygon fill="#17FFC6" points="4.6542 42.5022 13.7722 45.2102 14.6302 42.3202 5.5122 39.6102 6.2222 37.2242 3.4762 36.4082 1.2032 44.0582 3.9492 44.8752"></polygon>
    <path d="M9.6254,36.9084 C12.5934,38.6464 16.5394,37.8224 18.4204,34.6074 C19.9164,32.0534 19.5074,29.0644 17.7114,27.1094 L16.0634,29.9204 C16.6344,30.8804 16.6584,32.1474 16.0274,33.2244 C15.1104,34.7934 13.3534,35.3844 11.8924,34.7894 L16.8394,26.3364 C13.8294,23.8974 9.3094,24.7504 7.3374,28.1194 C5.4564,31.3354 6.6684,35.1784 9.6254,36.9084 M9.7464,29.5644 C10.5364,28.2164 11.9834,27.5854 13.3084,27.9094 L10.0394,33.4954 C9.1364,32.4794 8.9564,30.9134 9.7464,29.5644" fill="#17FFC6"></path>
    <g transform="translate(15.499100, 0.000000)">
        <mask id="mask-2" fill="white">
            <polygon points="0 0 94.0919 0 94.0919 109.729 0 109.729"></polygon>
        </mask>
        <path d="M39.227,0 C23.851,0 9.961,6.333 0,16.523 L8.839,25.362 C16.568,17.354 27.405,12.366 39.413,12.366 C61.004,12.366 78.821,28.471 81.54,49.321 L59.906,49.321 C57.465,40.279 49.227,33.616 39.413,33.616 C27.677,33.616 18.163,43.129 18.163,54.865 C18.163,66.6 27.677,76.114 39.413,76.114 C49.207,76.114 57.431,69.479 59.891,60.465 L81.532,60.465 C78.789,81.288 60.984,97.363 39.413,97.363 C27.855,97.363 17.382,92.743 9.72,85.257 L0.885,94.092 C10.777,103.762 24.302,109.729 39.227,109.729 C69.528,109.729 94.092,85.166 94.092,54.865 C94.092,24.564 69.528,0 39.227,0" fill="#17FFC6" mask="url(#mask-2)"></path>
    </g>
    <g transform="translate(89.591200, 0.008600)">
        <mask id="mask-4" fill="white">
            <polygon points="0 0 19.9998 0 19.9998 9.444 0 9.444"></polygon>
        </mask>
        <path d="M18.124,9.444 L17.778,3.888 C17.742,3.159 17.742,2.256 17.708,1.249 L17.604,1.249 C17.361,2.083 17.084,3.194 16.805,4.062 L15.104,9.305 L13.159,9.305 L11.457,3.922 C11.285,3.194 11.006,2.083 10.799,1.249 L10.694,1.249 C10.694,2.117 10.66,3.02 10.625,3.888 L10.277,9.444 L8.472,9.444 L9.166,0 L11.979,0 L13.61,4.618 C13.819,5.346 13.993,6.041 14.237,7.014 L14.27,7.014 C14.514,6.145 14.721,5.346 14.931,4.652 L16.562,0 L19.27,0 L20,9.444 L18.124,9.444 Z M7.916,0 L7.916,1.562 L4.895,1.562 L4.895,9.444 L3.021,9.444 L3.021,1.562 L0,1.562 L0,0 L7.916,0 Z" fill="#17FFC6" mask="url(#mask-4)"></path>
    </g>
</g>`,
];

export const logoLarge = [
    '410 138',
    `
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(263.268000, 0.000500)">
        <mask id="mask-2" fill="white">
            <polygon points="0 0 128.714 0 128.714 137.2415 0 137.2415"></polygon>
        </mask>
        <path d="M59.22,0 C34.175,0 12.231,13.088 0,32.71 C5.045,34.596 9.583,37.488 13.372,41.137 C22.803,25.751 39.912,15.466 59.456,15.466 C86.803,15.466 109.372,35.609 112.814,61.688 L85.413,61.688 C82.321,50.378 71.887,42.045 59.456,42.045 C44.591,42.045 32.54,53.944 32.54,68.621 C32.54,83.3 44.591,95.199 59.456,95.199 C71.861,95.199 82.277,86.899 85.394,75.626 L112.806,75.626 C109.331,101.668 86.777,121.775 59.456,121.775 C39.912,121.775 22.803,111.491 13.372,96.104 C9.583,99.754 5.045,102.645 0,104.532 C12.231,124.155 34.175,137.242 59.22,137.242 C97.601,137.242 128.714,106.518 128.714,68.621 C128.714,30.723 97.601,0 59.22,0" fill="#17FFC6" mask="url(#mask-2)"></path>
    </g>
    <g transform="translate(0.000000, 42.318000)">
        <mask id="mask-4" fill="white">
            <polygon points="0 0 45.7179 0 45.7179 52.669 0 52.669"></polygon>
        </mask>
        <path d="M21.5439,29.302 L12.6509,29.302 L12.6509,13.228 L20.7969,13.228 C29.2319,13.228 30.1419,18.844 30.1419,21.258 C30.1419,25.147 27.8829,29.302 21.5439,29.302 M21.5139,0 L-0.0001,0 L-0.0001,52.669 L14.1139,52.669 L14.1139,42.53 L23.0789,42.53 C39.7899,42.53 45.7179,31.055 45.7179,21.228 C45.7179,7.936 36.6709,0 21.5139,0" fill="#17FFC6" mask="url(#mask-4)"></path>
    </g>
    <polygon fill="#17FFC6" points="107.4172 55.5737 122.1502 55.5737 122.1502 94.9847 136.2652 94.9847 136.2652 55.5737 151.0602 55.5737 151.0602 42.2577 107.4172 42.2577"></polygon>
    <mask id="mask-6" fill="white">
        <polygon points="0 137.242 409.586 137.242 409.586 0 0 0"></polygon>
    </mask>
    <polygon fill="#17FFC6" mask="url(#mask-6)" points="156.976 94.984 171.153 94.984 171.153 42.443 156.976 42.443"></polygon>
    <polygon fill="#17FFC6" mask="url(#mask-6)" points="177.0682 55.5741 191.8012 55.5741 191.8012 94.9851 205.9162 94.9851 205.9162 55.5741 220.7092 55.5741 220.7092 42.2581 177.0682 42.2581"></polygon>
    <path d="M249.5163,54.392 C255.2403,54.392 260.1403,57.496 262.0233,62.208 L236.9713,62.208 C238.9823,57.496 243.8943,54.392 249.5163,54.392 M275.6683,78.01 L260.0543,78.01 C257.6683,81.142 253.7953,83.033 249.5783,83.033 C242.8753,83.033 237.3173,78.854 236.0363,72.978 L256.2673,72.978 L276.8363,72.978 L276.9293,72.365 C278.1213,64.664 275.8323,56.824 270.6403,50.857 C265.3743,44.799 257.7203,41.326 249.6423,41.326 C234.1233,41.326 221.9673,53.303 221.9673,68.589 C221.9673,83.914 234.1233,95.917 249.6423,95.917 C261.5913,95.917 271.9253,88.752 275.6683,78.01" fill="#17FFC6" mask="url(#mask-6)"></path>
    <path d="M64.358,62.2102 C66.368,57.4962 71.281,54.3922 76.903,54.3922 C82.626,54.3922 87.529,57.4962 89.411,62.2102 L64.358,62.2102 Z M78.491,41.3262 C62.973,41.3262 50.816,53.3032 50.816,68.5912 C50.816,83.9142 62.973,95.9172 78.491,95.9172 C90.439,95.9172 100.775,88.7522 104.52,78.0102 L88.904,78.0102 C86.521,81.1422 82.648,83.0342 78.43,83.0342 C71.727,83.0342 66.171,78.8532 64.888,72.9802 L85.408,72.9802 L85.408,72.9782 L105.685,72.9782 L105.779,72.3642 C106.972,64.6642 104.682,56.8242 99.492,50.8562 C94.224,44.7992 86.569,41.3262 78.491,41.3262 L78.491,41.3262 Z" fill="#17FFC6" mask="url(#mask-6)"></path>
    <path d="M407.1431,14.6985 L406.6921,7.4625 C406.6461,6.5125 406.6461,5.3365 406.6021,4.0255 L406.4651,4.0255 C406.1491,5.1115 405.7881,6.5585 405.4251,7.6895 L403.2101,14.5185 L400.6771,14.5185 L398.4601,7.5065 C398.2351,6.5585 397.8731,5.1115 397.6021,4.0255 L397.4661,4.0255 C397.4661,5.1555 397.4221,6.3325 397.3751,7.4625 L396.9231,14.6985 L394.5711,14.6985 L395.4751,2.3975 L399.1391,2.3975 L401.2641,8.4125 C401.5371,9.3605 401.7631,10.2665 402.0801,11.5335 L402.1241,11.5335 C402.4411,10.4015 402.7111,9.3605 402.9841,8.4565 L405.1081,2.3975 L408.6361,2.3975 L409.5861,14.6985 L407.1431,14.6985 Z M393.8481,2.3975 L393.8481,4.4325 L389.9131,4.4325 L389.9131,14.6985 L387.4721,14.6985 L387.4721,4.4325 L383.5371,4.4325 L383.5371,2.3975 L393.8481,2.3975 Z" fill="#17FFC6" mask="url(#mask-6)"></path>
</g>
`,
];

export const logoCurie = [
    '189 60',
    `
<defs>
    <polygon id="path-logo-curie" points="0 60 188.235294 60 188.235294 0 0 0"></polygon>
</defs>
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-1248.000000, -243.000000)">
        <g transform="translate(1248.000000, 243.000000)">
            <path d="M72.7786915,26.1612612 C72.7786915,29.5471549 70.3601223,32.2965432 66.5331003,32.2965432 C62.7094256,32.2965432 60.2908564,29.5471549 60.2908564,26.1612612 L60.2908564,0.787946465 L50.3860053,0.787946465 L50.3860053,25.8183759 C50.3860053,35.5354298 57.3166247,41.86659 66.5331003,41.86659 C75.7529232,41.86659 82.6837395,35.5844979 82.6837395,25.8183759 L82.6837395,0.787946465 L72.7786915,0.787946465 L72.7786915,26.1612612 Z" fill="#3E56C6"></path>
            <path d="M103.38201,23.6082043 L101.421242,23.6082043 L101.421242,10.4073175 L103.38201,10.4073175 C107.89406,10.4073175 110.298649,13.1567058 110.298649,16.9832269 C110.298649,20.764424 107.943089,23.6082043 103.38201,23.6082043 M119.958946,16.9832269 C119.958946,8.59199606 115.006422,0.788399704 101.665993,0.788399704 L91.5652227,0.788399704 L91.5652227,41.2756647 L101.421242,41.2756647 L101.421242,33.2273192 L103.088426,33.2273192 C104.168443,33.2273192 105.199432,33.1782511 106.129014,33.0312439 L109.512412,41.2267937 L120.941102,41.2267937 L114.810699,29.35173 C118.34079,26.3085245 119.958946,21.7438148 119.958946,16.9832269" fill="#3E56C6"></path>
            <polygon fill="#3E56C6" points="129.378193 41.226439 139.283241 41.226439 139.283241 0.885984071 129.378193 0.885984071"></polygon>
            <path d="M156.611877,16.49363 C157.985477,12.2718056 162.106083,9.17972904 167.2053,9.17972904 C172.304715,9.17972904 176.474152,12.2227375 177.749892,16.49363 L156.611877,16.49363 Z M167.303161,0.00143854175 C155.140415,0.00143854175 146.315384,9.77071352 146.315384,20.9568536 C146.315384,32.1989589 155.140415,41.9648838 167.303161,41.9648838 C176.963458,41.9648838 184.712016,35.8786698 187.263298,27.581831 L176.621042,27.581831 C174.855997,30.7751966 171.326103,32.9334034 167.254329,32.9334034 C161.319649,32.9334034 156.75857,28.9598752 156.024709,23.853052 L187.997356,23.853052 C189.909094,11.3412858 180.053075,0.00143854175 167.303161,0.00143854175 L167.303161,0.00143854175 Z" fill="#3E56C6" ></path>
            <path d="M20.9877383,32.0995221 C14.5637523,32.0995221 9.90500869,26.8983069 9.90500869,21.0079711 C9.90500869,15.1176353 14.5637523,9.91622301 20.9877383,9.91622301 C26.2058856,9.91622301 30.3160552,13.2881255 31.6126666,17.6989309 L41.7134371,17.6989309 C40.3187675,7.95054438 31.5392215,-5.91181542e-05 20.9877383,-5.91181542e-05 C9.21998042,-5.91181542e-05 -3.93807573e-05,9.76941292 -3.93807573e-05,21.0079711 C-3.93807573e-05,32.2463322 9.21998042,41.9633862 20.9877383,41.9633862 C31.6685873,41.9633862 40.504054,33.8904081 41.7902295,23.9914673 L31.6825675,23.9914673 C30.4767287,28.5668183 26.342143,32.0995221 20.9877383,32.0995221" fill="#3E56C6" ></path>
            <polygon fill="#3E56C6" points="29.3553813 54.734011 27.0362485 54.734011 27.0819302 53.431441 33.0404357 53.431441 33.0863143 54.734011 30.7669846 54.734011 30.7669846 59.853249 29.3553813 59.853249"></polygon>
            <polygon fill="#3E56C6" points="38.9710793 53.4313819 44.3152449 53.4313819 44.3611235 54.7065605 40.3736249 54.7065605 40.3736249 55.8901059 43.5819752 55.8901059 43.5819752 57.1650874 40.3736249 57.1650874 40.3736249 58.5780113 44.4985624 58.5780113 44.4528807 59.8531899 38.9710793 59.8531899"></polygon>
            <path d="M50.1545055,56.6790377 C50.1545055,54.8991871 51.3737337,53.2844733 53.7753692,53.2844733 C54.8295921,53.2844733 55.672931,53.6789884 56.1403806,54.0461122 L56.1403806,55.4771656 L56.131323,55.4771656 C55.443735,55.0184087 54.7654014,54.5689137 53.7479996,54.5689137 C52.3730204,54.5689137 51.6029297,55.4679038 51.6029297,56.6697758 C51.6029297,57.816471 52.3178874,58.7156581 53.7387451,58.7156581 C54.7378349,58.7156581 55.462047,58.3487314 56.131323,57.8440594 L56.1403806,57.8440594 L56.1403806,59.27531 C55.691243,59.6146482 54.8022225,60.0000985 53.6379304,60.0000985 C51.1719073,60.0000985 50.1545055,58.4220379 50.1545055,56.6790377" fill="#3E56C6" ></path>
            <polygon fill="#3E56C6" points="62.2635961 53.4313819 63.6751993 53.4313819 63.6751993 55.8808441 67.0119309 55.8808441 67.0119309 53.4313819 68.423731 53.4313819 68.423731 59.8531899 67.0119309 59.8531899 67.0119309 57.1834141 63.6751993 57.1834141 63.6751993 59.8531899 62.2635961 59.8531899"></polygon>
            <polygon fill="#3E56C6" points="74.8768982 53.4313819 76.4351948 53.4313819 79.6711116 57.6971508 79.7077357 57.6971508 79.7077357 53.4313819 81.0829118 53.4313819 81.0829118 59.8531899 79.625233 59.8531899 76.2885015 55.4404138 76.2518774 55.4404138 76.2518774 59.8531899 74.8768982 59.8531899"></polygon>
            <path d="M92.7794692,56.6422662 C92.7794692,55.348761 92.0369451,54.5597307 90.7078445,54.5597307 C89.378547,54.5597307 88.6177108,55.348761 88.6177108,56.6422662 C88.6177108,57.9357714 89.360235,58.7246046 90.6893355,58.7246046 C92.018633,58.7246046 92.7794692,57.9357714 92.7794692,56.6422662 M87.1692866,56.6422662 C87.1692866,54.6056458 88.5352081,53.2845521 90.69859,53.2845521 C92.8619719,53.2845521 94.2276966,54.6056458 94.2276966,56.6422662 C94.2276966,58.6788866 92.8619719,59.9999803 90.69859,59.9999803 C88.5352081,59.9999803 87.1692866,58.6788866 87.1692866,56.6422662" fill="#3E56C6" ></path>
            <polygon fill="#3E56C6" points="100.314308 53.4313819 101.725911 53.4313819 101.725911 58.5506199 105.383399 58.5506199 105.337717 59.8531899 100.314308 59.8531899"></polygon>
            <path d="M116.255048,56.6422662 C116.255048,55.348761 115.512523,54.5597307 114.183423,54.5597307 C112.854322,54.5597307 112.093289,55.348761 112.093289,56.6422662 C112.093289,57.9357714 112.835813,58.7246046 114.165111,58.7246046 C115.494211,58.7246046 116.255048,57.9357714 116.255048,56.6422662 M110.645062,56.6422662 C110.645062,54.6056458 112.010786,53.2845521 114.174365,53.2845521 C116.33755,53.2845521 117.703275,54.6056458 117.703275,56.6422662 C117.703275,58.6788866 116.33755,59.9999803 114.174365,59.9999803 C112.010786,59.9999803 110.645062,58.6788866 110.645062,56.6422662" fill="#3E56C6" ></path>
            <path d="M123.423369,56.7155924 C123.423369,54.9083504 124.734158,53.2845718 127.099169,53.2845718 C128.079947,53.2845718 128.969164,53.5965186 129.519117,54.0093604 L129.519117,55.4404138 L129.510059,55.4404138 C128.813413,54.9633303 128.089201,54.5688152 127.080857,54.5688152 C125.669057,54.5688152 124.871597,55.4404138 124.871597,56.7065276 C124.871597,57.844158 125.586554,58.7338862 126.9891,58.7338862 C128.070889,58.7338862 128.859095,58.2110847 128.904974,57.293768 L126.659286,57.293768 L126.659286,56.1102225 L130.316774,56.1102225 C130.326028,56.2570326 130.335086,56.4036456 130.335086,56.6789392 C130.335086,59.1284013 128.593472,60 126.915852,60 C124.651655,60 123.423369,58.5137696 123.423369,56.7155924" fill="#3E56C6" ></path>
            <polygon fill="#3E56C6" points="136.284002 59.8531899 137.695605 59.8531899 137.695605 53.4313819 136.284002 53.4313819"></polygon>
            <polygon fill="#3E56C6" points="144.167498 53.4313819 149.511664 53.4313819 149.557542 54.7065605 145.569847 54.7065605 145.569847 55.8901059 148.778197 55.8901059 148.778197 57.1650874 145.569847 57.1650874 145.569847 58.5780113 149.694981 58.5780113 149.649103 59.8531899 144.167498 59.8531899"></polygon>
            <path d="M155.52485,59.3485771 L155.52485,57.9082618 L155.534104,57.9082618 C156.441437,58.4036719 157.523226,58.7063568 158.412444,58.7063568 C159.283349,58.7063568 159.723232,58.449587 159.723232,58.0274834 C159.723232,57.5872502 159.356597,57.4587667 157.889861,57.2017998 C156.780702,57.0092717 155.52485,56.6604746 155.52485,55.3854931 C155.52485,54.3119074 156.405009,53.2846309 158.403189,53.2846309 C159.209904,53.2846309 160.135745,53.5140094 160.795767,53.8900008 L160.795767,55.3303161 L160.786709,55.3303161 C160.053243,54.8624945 159.118147,54.5781361 158.293317,54.5781361 C157.376533,54.5781361 157.000643,54.8532326 157.000643,55.2477478 C157.000643,55.789073 157.789046,55.8440529 158.79739,56.0275162 C159.906549,56.2295032 161.199026,56.5505148 161.199026,57.9358502 C161.199026,58.697095 160.786709,60.0000591 158.403189,60.0000591 C157.184158,60.0000591 156.267374,59.7155037 155.52485,59.3485771" fill="#3E56C6" ></path>
        </g>
    </g>
</g>
`,
];

<template>
    <svg
        height="13px"
        viewBox="0 0 10 13"
        width="10px"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
            <g
                fill="#FF174D"
                fill-rule="nonzero"
                transform="translate(-71, -76)"
            >
                <g transform="translate(71, 76)">
                    <path
                        d="M4.99998674,4.15306332 L7.8920436,7 L10,4.92493071 L7.10794314,2.07799402 L7.10903076,2.07692336 L5.30123326,0.297303952 L5.18642215,0.185015239 L5.00072951,0 L4.99895216,0.00174961482 L4.99895216,0.00174961482 L3.19107508,1.78147348 L2.89030605,2.07971752 L0,4.92493071 L2.10806251,7 L4.99998674,4.15306332 Z M3.79256009,2.37344391 L4.09322301,2.07517375 L4.99820939,1.18430644 L4.99929702,1.1853771 L5.00107436,1.18362748 L5.9085278,2.07692336 L5.90744017,2.07799402 L8.79949704,4.92493071 L7.8920436,5.81822658 L4.99998674,2.9712899 L2.10806251,5.81822658 L1.2006356,4.92493071 L3.79256009,2.37344391 Z"
                    />
                    <path
                        d="M7.10725342,8.0738506 L5.30123326,6.29552926 L5.00176408,6.00282103 L4.99889911,6 L3.19107508,7.78007142 L2.89205686,8.07669775 L0,10.9243993 L2.10806251,13 L4.99998674,10.1522984 L7.8920436,13 L10,10.9243993 L7.10616579,8.07494767 L7.10725342,8.0738506 Z M8.79949704,10.9243993 L7.8920436,11.8179091 L4.99998674,8.97020751 L2.10806251,11.8179091 L1.2006356,10.9243993 L3.79256009,8.37222701 L4.09115387,8.07601861 L5.00011937,7.18099385 L5.91043778,8.07735077 L5.90935016,8.07842171 L8.79949704,10.9243993 Z"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<template>
    <svg
        height="17px"
        viewBox="0 0 17 17"
        width="17px"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            fill="none"
            fill-rule="evenodd"
            stroke="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        >
            <g
                stroke="var(--ci-primary-color, currentColor)"
                transform="translate(-1314, -437)"
            >
                <g transform="translate(1315, 438)">
                    <path
                        d="M15,3.46142613 C15,3.94080409 14.9004814,4.41496029 14.7076739,4.85384856 C14.5147895,5.29272913 14.2328461,5.68675958 13.879763,6.01099242 C13.526603,6.33514067 13.1099173,6.58246232 12.6561621,6.73719215 C12.2024838,6.89192199 11.7215033,6.95067626 11.2438299,6.9097636 C10.4163034,6.83978142 9.50264045,6.96436509 8.97505391,7.60497123 L3.47463347,14.283271 C3.3011221,14.4947556 3.08524999,14.6675578 2.84091436,14.7906034 C2.59657103,14.913649 2.32917848,14.9841695 2.05594095,14.9976276 C1.78270342,15.0110857 1.50968123,14.9671738 1.25444009,14.8687373 C0.999198945,14.7703009 0.767399259,14.6194931 0.573961114,14.4260808 C0.380515278,14.2325916 0.22972243,14.0008044 0.131280625,13.7455617 C0.0328311303,13.4903959 -0.0110754525,13.2173885 0.00236800636,12.9441504 C0.015819156,12.6709123 0.0863280983,12.4035188 0.209364972,12.1591964 C0.332394155,11.914874 0.505221048,11.6990058 0.716724802,11.5255116 L7.39537058,6.02537338 C8.03601138,5.49783076 8.16060179,4.5842172 8.09061582,3.75673556 C8.03901078,3.15548094 8.14552789,2.55118095 8.39955389,2.00379723 C8.6536568,1.45640582 9.04642421,0.984971996 9.53894086,0.636260775 C10.0314575,0.287549553 10.6066499,0.0736886156 11.2073756,0.0159033271 C11.8081014,-0.0418819613 12.4135185,0.0584155809 12.9634851,0.306844641 L10.4439902,2.82620324 C10.5415091,3.24770362 10.7553893,3.63337466 11.0612511,3.93928909 C11.3671897,4.24519582 11.7528817,4.45906445 12.1744125,4.55653194 L14.6939075,2.03717335 C14.8907911,2.47167811 15,2.95386304 15,3.46142613 Z"
                    ></path>
                    <polygon
                        points="1.72615385 13.2646154 1.73538462 13.2646154 1.73538462 13.2738462 1.72615385 13.2738462"
                    ></polygon>
                </g>
            </g>
        </g>
    </svg>
</template>

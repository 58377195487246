<template>
    <svg
        height="8px"
        viewBox="0 0 11 8"
        width="11px"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
            <g
                fill="#768192"
                fill-rule="nonzero"
                transform="translate(-70, -30)"
            >
                <path
                    d="M75.5011457,38 L75.2394385,37.7315966 L73.2731854,35.6957597 L73.2743998,35.6944785 L70,32.3042403 L72.2255085,30 L75.5,33.3903332 L78.7744915,30 L81,32.3042403 L77.727777,35.6920586 L77.4674447,35.9641631 L75.5011457,38 Z M75.4987639,36.9987112 L75.5000116,37 L76.6976502,35.7395183 L76.9597976,35.4610135 L80,32.2612842 L78.8015989,31 L75.4999191,34.4749269 L72.1982394,31 L71,32.2612842 L74.3016797,35.7362111 L74.3004552,35.7375243 L75.4987639,36.9987112 Z"
                />
            </g>
        </g>
    </svg>
</template>

<template>
    <svg
        width="10px"
        height="6px"
        viewBox="0 0 10 6"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path
                d="M1,1.00635613 C1,1.00635613 5,4.62635613 5,4.62635613 C5,4.62635613 6.33333333,3.41968946 9,1.00635613"
                stroke="#636F83"
            ></path>
        </g>
    </svg>
</template>
